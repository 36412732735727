import * as React from 'react';
import Header from '../../layouts/header-dark';
import Footer from '../../layouts/footer';
import Typing from 'react-typing-animation';
import '../../styles/_bootstrap.scss';
import { render } from 'react-dom';

class TermsOfService extends React.PureComponent<{}, {}> {
    public render() {
        return (
            <>
            <Header/>
            <div className="page">
                <div className="container-fluid hero">
                </div>
                <div className="container body">
                    <h1 className="text-center padding-top-L padding-bottom-L">Website Terms &amp; Conditions</h1>
                    <section className="padding-bottom-M text-"><h4>Definitions</h4>
                        <ul className="list-unstyled">
                            <li>1. The following definitions apply: 'Company' means DirekTek Ltd.'Contract' means these terms and conditions, appropriate purchase orders and the Special Conditions. 'Customer' means the Customer who purchases the Goods. 'Goods' means the products including but not limited to computer hardware and software items provided by the Company as set out in the appropriate purchase order. 'Third Party Software' means all software owned by or licensed to the Customer from a third party owner (whether or not supplied by the Company) and which comprises part of the goods. 'Special Conditions' means an additional term or condition which has been agreed by the parties in writing and signed by an authorised signatory of the Company and Customer. 'Standard Charges' means the Company's charges for the Goods and the services to be provided under the Contract for time to time in force.</li>
                        </ul>
                    </section><section className="padding-bottom-M text-justify"><h4>General</h4>
                        <ul className="list-unstyled">
                            <li>2a. These terms and conditions apply to all contracts made between the Company and the Customer whether written or verbal and supersede all earlier conditions of sale.</li>
                            <li>2b. The Contract supersedes all prior agreements or contracts between the parties and constitutes the entire agreement between the parties and may not be modified, waived or amended or supplemented except by written agreement between the parties.</li>
                            <li>2c. A contract will not be deemed to be binding on the Company unless the Customer's purchase order has been accepted by the Company in writing and signed by one of its directors or other authorised signatory. The Company may, at its discretion accept any order placed by the Customer. The written order will not be accepted by the Company except in accordance with the terms of this clause.</li>
                            <li>2d. No quotation or estimate given by the Company shall be deemed to be binding on the parties unless expressed in writing as a 'fixed quotation' by the Company. It will be valid only until the date specified in the 'fixed quotation'. In which case it will be open to acceptance by the Customer. On receipt of a written acceptance of the fixed quotation by the Customer the Goods will be delivered to the Customer by a date to be notified by the Company.</li>
                        </ul>
                    </section><section className="padding-bottom-M text-justify"><h4>Price</h4><ul className="list-unstyled">
                        <li>3a. Unless the Customer accepts the 'fixed quotation' or part of the Contract signed on behalf of the Company which states that the price is fixed, the Company reserves the right to increase the price of the Goods in accordance with its Standard Charges at the date of delivery to the Customer.</li>
                        <li>3b. A 'fixed' quotation' takes into account freight rates, currency exchange rates, import and other duties and taxes of whatsoever kind (other than VAT) deemed by the Company applicable at the date ofthe Contract. It may be adjusted in accordance with any changes beyond the control of the Company including but not limited to, rates, duties or taxes payable or collectable by the Company including those payable by the Company in obtaining a supply from overseas. If requested, the Company will produce proof of all charges and changes to the Customer.</li>
                        <li>3c. The 'price' is the price of the Goods, plus any additional charges mentioned in clause 3b, plus VAT, which is payable by the Customer on all Goods.</li>
                        <li>3d. Unless otherwise agreed by the parties in writing, the price does not include installation, operator training, travelling or hotel expenses. If any such services are provided by the Company the Customer will pay the Company, its Standard Charges for them.</li>
                        <li>3e. The Customer shall pay the Company's delivery charges for the Goods unless specific arrangements are made between the parties or where an installation is carried out by the Company.</li>
                    </ul></section><section className="padding-bottom-M text-justify"><h4>Payment</h4><ul className="list-unstyled">
                        <li>4a. The price of the 'Goods' is payable on demand. If agreed, a 30 day credit term can be allowed by the Company with the Customer. If the Customer does not pay any sums due to the Company on demand or within the agreed credit term, (whichever is appropriate), without prejudice to any other right of the Company, the Company reserves the right to charge interest on the balance of the invoice at the rate of 5% per month. The Customer will also be obliged toreimburse the Company for all costs and expenses (including legal) incurred in collecting any over-due amounts.</li>
                        <li>4b. Payment in full of the price together with any additional charges incurred in accordance with these terms and conditions shall become due immediately if the Customer becomes unable to pay its debts or they fall due or has a receiver, administrator or administrative receiver appointed over a substantial part of its assets or a resolution is passed for an order made for its winding up (other than for the purposes of insolvent reconstruction), or if any composition or arrangement is entered into with its creditors or it ceases or threatens to cease
      to carry on business.</li>
                    </ul></section><section className="padding-bottom-M text-justify"><h4>Delivery and Acceptance</h4>
                        <ul className="list-unstyled">
                            <li>5a. Whilst the Company will use its best endeavours to deliver the Goods on the agreed date, the Company shall be under no liability whatsoever for any loss or damage resulting to the Customer, due to a delay by the Company delivering the Goods.</li>
                            <li>5b. The Customer has no right to modify, cancel or change the delivery date without the prior written consent of the Company.</li>
                            <li>5c. The Company reserves the right to make delivery by instalments. In this event, these terms and conditions apply to each delivery as though it were a separate contract.</li>
                            <li>5d. The Company reserves the right to use its own chosen carrier for delivery of the Goods to the Customer. If the Customer collects the Goods personally or by a nominated carrier from the Company's premises, no delivery charges will be made. Goods will only be delivered to the address on the invoice unless otherwise agreed by the Parties in writing at the time of placing the order.</li>
                            <li>5e. The Company reserves the right to withhold any deliveries if payment following previous transactions is overdue.</li>
                            <li>5f. Delivery will be deemed to have been made, when the Customer or nominated person accepts and signs for delivery of the Goods. The Customer must inform the Company within 3 days of delivery if the order is short or damaged in any way. After that time the Customer cannot make a claim against the Company in respect of any damage or shortage.</li>
                            <li>5g. If the Company agrees to arrange for delivery of the Goods to any Customer on a specific date, the Customer must give verbal and written notice to the Company immediately if the Goods are not received within 3 days of the expected delivery date. If the Customer fails to do so, the delivery will be deemed to have been made and payment will become due, whether or not it has actually been delivered.</li>
                        </ul>
                    </section>
                    <section className="padding-bottom-M text-justify"><h4>Property Risk and Insurance</h4>
                        <ul className="list-unstyled">
                            <li>6a. Risk on the goods passes from the Company to the Customer on delivery of the Goods to the Customer, but ownership of the Goods remains with the Company until full payment is received.</li>
                            <li>6b. The Company reserves the right to repossess the Goods at any time if i) payment is overdue, ii) the Customer has a receiver, administrator or administrative receiver appointed over part of its assets iii) a resolution is passed or an order is made for the Customer's winding up (other than for the purpose of solvent reconstruction) iv) the Customer ceases or threatens to cease to carry on business, whether this be at the premises of the Customer or elsewhere, and without prejudice to the other rights of the Company under the Contract. The
           Customer will be liable for all transport and other costs and expenses incurred in the recovery of the Goods.</li>
                            <li>6c. If the Customer sells or otherwise disposes of the Goods to a third party at any time before the Company has received full payment, the Customer must hold and keep the proceeds of sale on trust and/or in a fiduciary capacity for the Company and the said proceeds of sale must remain the property of the Company and held in a separate bank account.</li>
                            <li>6d. Where the Company supplies Goods to the Customer on loan, evaluation, service loan or hire, then the risk in the Goods also passes from the Company to the Customer on delivery to the Customer. In the event of loss, the Customer will indemnify the Company to the full retail value currently recommended by the manufacturer and will reimburse the Company the full retail value.</li>
                            <li>6e. The Customer shall keep the Goods marked and separately stored at its expense so as to be clearly identified as the Company's property.</li>
                            <li>6f. The Customer must hold at all times suitable insurance cover for the Goods loaned or hired from the Company with reputable insurers against all insurable risks from no later than the time of their leaving the premises of the Company, at the same time shall also insure them for the benefit of the Company and the Customer and all other persons in anyway connected with the Goods against all known and insurable risks to persons and property which might in any way arise out of the Goods or their use.</li>
                        </ul>
                    </section><section className="padding-bottom-M text-justify"><h4>Copyright</h4>
                        <ul className="list-unstyled">
                            <li>7a. The Customer is not entitled to any rights of copyright or design or any similar rights in respect of any of the Goods.</li>
                            <li>7b. The Company is free to produce similar goods and sell them to other parties without any restriction.</li>
                        </ul>
                    </section><section className="padding-bottom-M text-justify"><h4>Force Majeure</h4>
                        <ul className="list-unstyled">
                            <li>8a. The Company is not liable for any failure in performance of any obligations under the Contract caused by factors outside its control. In the event of such failure, the Company may terminate the Contract without liability to the Customer.</li>
                        </ul>
                    </section><section className="padding-bottom-M text-justify"><h4>Warranty</h4>
                        <ul className="list-unstyled">
                            <li>10a. The Company warrants all products in accordance with the relevant manufacturers warranty/DOA period. Details of which are available from the specific manufacturer.</li>
                            <li>10b. The warranty period on all products, unless otherwise stated is for one year from date of sale from the reseller to the end user or from thirteen months from the date of invoice from the Company, which ever is the sooner.</li>
                            <li>10c. The express terms of the Contract are in lieu of all warranties, conditions, terms, undertakings and obligations implied by statute, common law, custom, trade usage, cause of dealing or otherwise, all of which are excluded to the fullest extent permitted by law.</li>
                            <li>10d. The Company has no liability or obligation to replace the goods if any part has been modified or repaired improperly, stored or used, damaged by accident or neglect or maintained other than in accordance with the agreed maintenance agreement.</li>
                        </ul>
                    </section><section className="padding-bottom-M text-justify"><h4>Maintenance</h4>
                        <ul className="list-unstyled">
                            <li>11. Maintenance services can be arranged by the Company from the date of delivery of the Goods. Such services cover the maintenance and technical support and repair services direct from the manufacturer of the Goods. Details of the charges for such maintenance services will be provided by the Company on the Customer's written request.</li>
                        </ul>
                    </section><section className="padding-bottom-M text-justify"><h4>Liability</h4>
                        <ul className="list-unstyled">
                            <li>12a. Nothing in this Contract shall exclude or restrict either party's liability for death or personal injury resulting from it or its employees negligence.</li>
                            <li>12b. The liability of the Company for loss of or damage to any tangible property in respect of each event or series of connected events shall not exceed&#163;1.000.000 (one million pounds).</li>
                            <li>12c. In no event shall the Company be liable to the Customer for loss of profits, loss of contracts, loss of revenue, loss of data, loss of goodwill, third party claims or indirect or consequential losses incurred by the Customer arising from breach of contract, negligence or however.</li>
                            <li>12d. In any event (including without limitation in the event that any exclusion or other provision contained in this clause shall be held ineffective or any reason) and save in respect of loss or damage to tangible property, as referred to in 10b above, the maximum aggregate liability of the Company howsoever arising from or in connection with the performance or non-performance of its obligations under the Contract (whether for breach of contract, negligence, misrepresentation or otherwise) shall not in any circumstances exceed &#163;1.000.000 (one million pounds).</li>
                            <li>12e. The Customer shall indemnify the Company against all liability in respect of any claims which may be made against the Company and any third party or damage to person or property alleged to arise out of or in respect of the Goods or to their use.</li>
                        </ul>
                    </section><section className="padding-bottom-M text-justify"><h4>Returns</h4>
                        <ul className="list-unstyled">
                            <li>14. The Company reserves the right to levy a re-stocking charge in respect of the rotation of goods and returns.</li>
                            <li>14a. Returns must be subject to the following:</li>
                            <li>i) prior authority must be received from the Company with an appropriate Return Materials Authorisation (RMA) number quoted,</li>
                            <li>ii) within 30 days of the date of the invoice,</li>
                            <li>iii) subject to stock rotation policy,</li>
                            <li>iv) the Goods to be returned must be properly packed and in a saleable condition,</li>
                            <li>v) the Goods must be accompanied by a detailed packing list,</li>
                            <li>vi) the Goods are still covered by warranty (section 10).</li>
                            <li>14b. The Company reserves the right to reject any Goods returned which do not comply with the conditions as set out in clause 14a.</li>
                            <li>14c. If the Company never-the-less agrees to accept any Goods returned in a non-saleable condition, the Company reserves the right to charge the cost to the Customer of bringing the Goods to a saleable condition.</li>
                        </ul>
                    </section><section className="padding-bottom-M text-justify"><h4>Notices</h4>
                        <ul className="list-unstyled">
                            <li>15. All notices under the Contract shall be in writing and shall be sent to the address of the recipient set out in the Contract of such other address as the recipient may designate by notice given in accordance with the provision of this clause. Any such notice may be delivered personally or by first class pre-paid letter or facsimile transmission and shall be deemed to have been made, if by hand, when delivered, if by first class post, 48 hours after posting and if by facsimile transmission when despatched.</li>
                        </ul>
                    </section><section className="padding-bottom-M text-justify"><h4>Law</h4>
                        <ul className="list-unstyled">
                            <li>16. The Contract shall be governed by English Law and the parties.</li>
                        </ul>
                    </section>

                    <br /><br />
                    <div className="text-left">
                        <p>For more information regarding our Terms and Conditions please contact our sales team:
        <br />
                            Tel: (+44)01494 471100
        <br />
                            Email: <a href="mailto:sales@direktek.co.uk">sales@direktek.co.uk</a></p>
                    </div>
                </div>
            </div>
            <Footer/>
            </>

        );
    }
}
export default TermsOfService;